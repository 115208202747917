import AppStoreButtons from "./AppStoreButtons";

const Location = () => {
  return (
    <>
      <section className="relative pb-10 dark:bg-jacarta-800">
        <div className="container">
          <div className="lg:flex lg:flex-nowrap lg:items-center lg:justify-between">
            <div className="mb-16 lg:w-2/3 lg:pl-5 max-w-2xl">
              <h2 className="mb-6 font-display text-3xl text-jacarta-700 dark:text-white">
                Įvairių sporto šakų lokacijos vienoje vietoje
              </h2>
              <p className="mb-12 text-lg leading-normal dark:text-jacarta-300 font-light">
                Svarbu žaisti šalia namų? O gal tinkama aikštelė yra šalia
                ofiso?
                <br />
                Kuriant ar ieškant sau tinkamų sporto įvykių – gausus sporto
                aikštelių sąrašas leis atrasti geriausią vietą žaidimams.
                Lokacijos rūšiavimo funkcija padės sutaupyti laiko, nes
                arčiausiai tavęs esančias sporto erdves rasi vos vieno mygtuko
                paspaudimu.
              </p>

              <div className="flex flex-col sm:flex-row space-x-4 items-center mb-10">
                <AppStoreButtons gaSectionName="Įvairių sporto šakų lokacijos Section" />
              </div>

              <div className="mb-16 lg:w-1/3 lg:pr-20 text-center lg:hidden">
                <img
                  src="/images/app_screen_4.png"
                  alt="Shareball find location"
                  width="290"
                  className="rounded-2.5xl inline-block"
                />
              </div>
            </div>
            <div className="mb-16 lg:w-1/3 lg:pr-20 text-center hidden lg:block">
              <img
                src="/images/app_screen_4.png"
                alt="Shareball find location"
                width="290"
                className="rounded-2.5xl inline-block"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Location;
