import React, { useContext, useEffect, useRef } from "react";
import UserContext from "../components/UserContext";
import DownloadAppBanner from "../components/crypto/DownloadAppBanner";
import Feature from "../components/crypto/feature";
import Hero_10 from "../components/hero/hero_10";
import Meta from "../components/Meta";
import Story from "../components/about/Story";
import Features from "../components/cryto-trading/Features";
import Section from "../components/section";
import Location from "../components/Location";

export default function Home() {
  const { scrollRef } = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, scrollRef.current.scrollPos);
    const handleScrollPos = () => {
      scrollRef.current.scrollPos = window.scrollY;
    };
    window.addEventListener("scroll", handleScrollPos);
    return () => {
      window.removeEventListener("scroll", handleScrollPos);
    };
  });

  return (
    <main>
      <Meta
        title="Mėgautis sportu su puikia komanda dar nebuvo taip paprasta!"
        desc="Trūksta žaidėjų komandai? Esi vienas ir nori prisijungti prie bendraminčių? Nuo šiol organizuok ir junkis į žaidimą pagal sporto šaką, miestą ir lygį patogiai su Shareball."
      />
      <Hero_10 />
      <Features />
      <Location />
      <Feature />
      <Story />
      <DownloadAppBanner />
    </main>
  );
}
